document.addEventListener('turbolinks:load', event => {
  var defaultMinValue = 1895;
  var defaultMaxValue = 1970;
  $(".menu").on("click", function (e) {
    $(".menu-closed").toggleClass("hidden")
    $(".menu-opened").toggleClass("hidden")
  });

  $(".menu-items").on("mouseout", function (e) {
    $(".menu-closed").toggleClass("hidden")
    $(".menu-opened").toggleClass("hidden")
  });

  $(".filter-navbar").on("click", function (e) {
    $(".side-filters").toggleClass("hidden")
  });
});


