document.addEventListener('turbolinks:load', event => {
  if ($(".side-filters").length === 0) {
    return true
  }

  $(".close-filter").on("click", function (e) {
    $("#side_filters").addClass("hidden")
  });

  $(".checkbox-class").on("click", function (e) {
    count = $('.'+ $(this).data("filter") +'_descriptor').filter(':checked').length
    if (count > 0) {
      $("#"+ $(this).data("filter") +"_indicator").addClass('bg-orange')
      $("#"+ $(this).data("filter") +"_count").text(count)
    } else {
      $("#"+ $(this).data("filter") +"_indicator").removeClass('bg-orange')
      $("#"+ $(this).data("filter") +"_count").text("Tout")
    }
  });

  var active_index = $(".descriptor-filter").index($(".is-opened"));
  var active_option = false
  if (active_index > -1 ) {
    active_option = active_index;
  }

  $("#accordion").accordion({
    icons: false,
    collapsible: true,
    active: active_option,
    heightStyle: "fill",
    animate:300,
  });

  $(".filter-navbar").on("click", function (e) {
    $("#accordion").accordion("refresh");
  });

  $(window).on("resize", function (e) {
    $("#accordion").accordion("refresh");
  });

});
