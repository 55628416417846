document.addEventListener('turbolinks:load', event => {
  scrollArrowShow();

  $("#show-keywords").on("click", ".close-results", function () {
    $("#results").addClass("hidden")
    $(".selected-keyword").addClass("hidden")
    $(".keyword").removeClass("hidden")
  });

  $("#show-keywords").on("click", ".keyword", function () {
    $("#spinner").removeClass("hidden")
    $("#keyword_results").addClass("hidden")
  });

  $("#words_results").on("click", ".close-results", function () {
    $("#results").addClass("hidden")
  });

  $("#show-keywords").on("click", "#scroll-right", function () {
    $('#scrolling-content').animate({
      scrollLeft: "+=" + ($("#results").width() - 20) + "px"
    }, function(){
      scrollArrowShow();
    });
  });

  $("#show-keywords").on("click", "#scroll-left", function () {
    $('#scrolling-content').animate({
      scrollLeft: "-=" + ($("#results").width() - 20) + "px"
    }, function(){
      scrollArrowShow();
    });
  });

  $("#words_results").on("click", "#scroll-right", function () {
    $('#scrolling-content').animate({
      scrollLeft: "+=" + ($("#results").width() - 20) + "px"
    }, function(){
        scrollArrowShow();
    });
  });

  $("#words_results").on("click", "#scroll-left", function () {
    $('#scrolling-content').animate({
      scrollLeft: "-=" + ($("#results").width() - 20) + "px"
    }, function(){
      scrollArrowShow();
    });
  });

  $("#scrolling-content").on("scroll", function () {
    scrollArrowShow();
  });

  function scrollArrowShow() {
    var el = $('#scrolling-content');
    if (el.length === 0) {
      return;
    }
    if ( 0 == el.scrollLeft()) {
        $('#scroll-left').addClass("hidden");
    } else {
        $('#scroll-left').removeClass("hidden");
    }
    if ( Math.abs(el.scrollLeft()) === el.get(0).scrollWidth - el.get(0).clientWidth) {
        $('#scroll-right').addClass("hidden");
    } else {
        $('#scroll-right').removeClass("hidden");
    }
  }
});
