document.addEventListener('turbolinks:load', event => {
  $(".filter-navbar").on("click", function (e) {
    $("#slider-range").slider("values",0,minValue)
    $("#slider-range").slider("values",1,maxValue)
  });

  if ($("#slider-range").length === 0) {
    return true
  }

  var defaultMinValue = 1895;
  var defaultMaxValue = 1970;
  let params = new URLSearchParams(window.location.search);
  var minValue = params.get('min_period') || defaultMinValue;
  var maxValue = params.get('max_period') || defaultMaxValue;

  $("#slider-range").slider({
    range: true,
    min: 1895,
    max: 1970,
    values: [ minValue, maxValue ],
    slide: function( event, ui ) {
      var delay = function() {
        var label = ui.handleIndex == 0 ? '#min' : '#max';
        var pos_my = ui.handleIndex == 0 ? 'right bottom' : 'left bottom';
        var pos_at = ui.handleIndex == 0 ? 'right top' : 'left top';
        $(label).attr('value', ui.value).position({
            my: pos_my,
            at: pos_at,
            of: ui.handle,
            offset: '0, 10'
          });
      };

      // wait for the ui.handle to set its position
      setTimeout(delay, 5);
    },
    change: function( event, ui ) {
      var delay = function() {
        var is_min_handle = ui.handleIndex == 0
        var label = is_min_handle ? '#min' : '#max';
        var pos_my = is_min_handle ? 'right bottom' : 'left bottom';
        var pos_at = is_min_handle ? 'right top' : 'left top';
        $(label).attr('value', ui.value).position({
          my: pos_my,
          at: pos_at,
          of: ui.handle,
          offset: '0, 10'
          });
      };

      // wait for the ui.handle to set its position
      setTimeout(delay, 5);
    },
    stop: function( event, ui ) {
      var delay = function() {
        let params = new URLSearchParams(window.location.search);
        params.set("opened_attribute","period");
        ui.handleIndex == 0 ? params.set("min_period", ui.value) : params.set("max_period", ui.value);
        Turbolinks.visit(window.location.pathname + '?' + params.toString())
      };

      // wait for the ui.handle to set its position
      setTimeout(delay, 5);
    }
  });

  $('#min').attr('value', $('#slider-range').slider('values', 0)).position({
    my: 'right bottom',
    at: 'right top',
    of: $('#slider-range span:eq(0)'),
    offset: "0, 10"
  });

  $('#max').attr('value', $('#slider-range').slider('values', 1)).position({
      my: 'left bottom',
      at: 'left top',
      of: $('#slider-range span:eq(1)'),
      offset: "0, 10"
  });
});
